import { useState, useEffect } from 'react';
import { sendAudioToAPI } from '../api';
import { useAudioRecorder } from './useAudioRecorder';
import hark from 'hark';

export const useSpeechRecognition = ({
    language = 'en-US',
}) => {

    const [loading, setLoading] = useState(false);
    const [audioData, setAudioData] = useState('');
    const [transcription, setTranscription] = useState('');
    const [speechEvents, setSpeechEvents] = useState(null);

    const onData = (recordedData) => {
        if (recordedData) {
            setAudioData(recordedData);
        }
    };

    const audioRecorder = useAudioRecorder({ onData });

    useEffect(() => {
        if (audioRecorder.stream && audioRecorder.audioContext) {
            const speechEvents = hark(audioRecorder.stream, {
                audioContext: audioRecorder.audioContext
            });
            speechEvents.on('speaking', () => {
                console.log('start speaking');
            });
            speechEvents.on('stopped_speaking', () => {
                console.log('stop speaking');
                audioRecorder.stop();
                audioRecorder.start();
            });
            setSpeechEvents(speechEvents);
        }
    }, [audioRecorder.stream, audioRecorder.audioContext])

    const [error, setError] = useState(null);

    useEffect(() => {
        setError(null);
        if (audioData !== '') {
            const params = {
                audioBlob: audioData,
                language
            };

            sendAudioToAPI(params, setLoading).then(data => {
                if (data.transcript) {
                    setTranscription(data.transcript);
                } else if ('error' in data) {
                    setError(data.error.message);
                }
            });
        }
    }, [audioData, language]);

    const startRecording = () => audioRecorder.start();

    const stopRecording = () => {
        if (audioRecorder.isRecording) {
            audioRecorder.stop();
            audioRecorder.destroy();
        }
        if (speechEvents) {
            speechEvents.stop();
        }
    };

    return {
        isRecording: audioRecorder.isRecording,
        transcription,
        loading,
        startRecording,
        stopRecording,
        error,
    };
};
