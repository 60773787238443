import { useState } from "react";
import { LiveModel } from "./components/LiveModel";
import { InterviewAI } from "./components/InterviewAI";
import { EmailBox } from "./components/EmailBox";

function App() {

  const [email, setEmail] = useState("");

  return (
    <div className='flex flex-col md:flex-row p-1 debug'>
      <LiveModel email={email} />
      <div className='flex-1'>
        {email ?
          <InterviewAI email={email} /> :
          <EmailBox
            value={email}
            onSave={value => setEmail(value)} />}
      </div>
    </div>
  )
}

export default App;


