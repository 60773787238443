const JSON_HEADER = {
    "Content-Type": "application/json",
}

const API_URL = process.env.REACT_APP_API_URL;

export const getFirstQuestion = ({ email, language }, setLoading = () => { }) => {

    return _fetch(`${API_URL}/first-question?email=${email}&language=${language}`, {
        method: 'GET',
        setLoading
    });
}

export const getNextQuestion = ({ email, answer, language }, setLoading = () => { }) => {

    return _fetch(`${API_URL}/next-question?language=${language}`, {
        method: 'POST',
        headers: JSON_HEADER,
        body: JSON.stringify({
            email, answer
        }),
        setLoading,
    });
}

export const getRating = ({ email, answer, language }, setLoading = () => { }) => {

    return _fetch(`${API_URL}/get-rating?language=${language}`, {
        method: 'POST',
        headers: JSON_HEADER,
        body: JSON.stringify({
            email, answer
        }),
        setLoading,
    })
}

export const getQuestionCount = (setLoading = () => { }) => {
    return _fetch(`${API_URL}/question-count`, { method: 'GET', setLoading });
}

export const sendAudioToAPI = ({ language, audioBlob }, setLoading = () => { }) => {
    let data = new FormData();
    data.append('channel_count', 1);
    data.append('sample_rate_hertz', 44100);
    data.append('audio', audioBlob, 'audio.flac');

    return _fetch(`${API_URL}/speech-to-text?language=${language}`, {
        method: 'POST',
        body: data,
        setLoading,
    });
};

export const getTextToSpeech = ({
    text,
    language,
    gender,
    voiceName,
}, setLoading) => {

    return _fetch(`${API_URL}/text-to-speech?language=${language}&gender=${gender}&voiceName=${voiceName}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            text
        }),
        setLoading,
        blobResponse: true,
    })
}

function _fetch(url, {
    setLoading = () => { },
    blobResponse = false,
    ...rest
}) {
    setLoading(true);
    return fetch(url,
        rest).then(it => {
            if (it.status == 200) {
                setLoading(false);
                return blobResponse ? it.blob() : it.json();
            } else {
                throw new Error(it.statusText);
            }
        }).catch(error => {
            setLoading(false);
            console.error(error);
            return { error };
        });
}