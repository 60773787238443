import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LanguageSwitch } from "./LanguageSwitch";

export const EmailBox = ({ value, onSave = () => { } }) => {

    const { t } = useTranslation();

    const [email, setEmail] = useState("");

    return (
        <div className="flex flex-col h-full border border-sky-400 gap-3 p-2">
            <header className="flex justify-end">
                <LanguageSwitch />
            </header>
            <div className="flex-grow flex flex-col justify-center items-start gap-3 p-3">
                <h1 className="text-black">{t('Enter email')}</h1>
                <input
                    type="email"
                    value={email} onChange={e => setEmail(e.target.value)}
                    className="max-w-prose border border-slate-400 rounded-md px-2 py-1" />
                <button onClick={() => isValidEmail(email) && onSave(email)} className="bg-lime-500 px-2 py-1 text-stone-50 max-w-prose">Ready</button>
            </div>
        </div>
    )
}

function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}