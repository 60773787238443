import { useState, useEffect } from "react";
import { getQuestionCount } from "../api";

export const useQuestionCount = () => {

    const [data, setData] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            const result = await getQuestionCount(setLoading);
            if (result.data) {
                setData(result.data.question_count);
            }
        })();
    }, [])

    return {
        data,
        loading
    }
}