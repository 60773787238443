import * as PIXI from 'pixi.js';
import { Live2DModel } from 'pixi-live2d-display/cubism4';
import { useEffect, useRef } from 'react';

export const LiveModel = ({ email }) => {
    const MODEL_NAME = "Haru"
    const MODEL_JSON = getModelJson(MODEL_NAME);
    const containerRef = useRef();

    useEffect(() => {
        // expose PIXI to window so that this plugin is able to
        // reference window.PIXI.Ticker to automatically update Live2D models
        window.PIXI = PIXI;

        const app = new PIXI.Application({
            view: document.getElementById('canvas'),
            backgroundAlpha: 0
        });

        const model = Live2DModel.fromSync(MODEL_JSON);

        model.once('load', () => {
            app.stage.addChild(model);
            model.scale.set(0.33);
        });
    }, [])
    return (
        <div className='w-full sm:4/6 md:w-3/6 debug border border-sky-500 b-r-0' ref={containerRef}>
            <h1 className='p-1'>Interview AI {email ? "-" : ""} {email}</h1>
            <canvas id="canvas" className='w-full' ></canvas>
        </div>
    );
}

function getModelJson(modelName) {
    return `https://cdn.jsdelivr.net/gh/Live2D/CubismWebSamples/Samples/Resources/${modelName}/${modelName}.model3.json`
}