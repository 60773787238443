import { useContext, useMemo } from 'react';
import { HiMiniLanguage } from 'react-icons/hi2';
import { Switch } from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { LANGUAGES } from "../constants";
import { LanguageContext } from '../context/language.context';

export const LanguageSwitch = ({
    onChange = () => { },
}) => {

    const { i18n } = useTranslation();
    const { language, setLanguage } = useContext(LanguageContext);

    const switchComponent = useMemo(() => {
        return (
            <Switch
                defaultValue={language === LANGUAGES.jp.code}
                checked={language === LANGUAGES.jp.code}
                onChange={(e) => {
                    const targetLang = e.target.checked ? LANGUAGES.jp.code : LANGUAGES.en.code;
                    onChange(targetLang);
                    setLanguage(targetLang);
                    i18n.changeLanguage(targetLang);
                }}
                size="lg"
                thumbIcon={
                    language === LANGUAGES.en.code ?
                        <EN /> : <JP />
                } />
        )
    }, [language])

    return (
        <div className="flex gap-2 items-center">
            <HiMiniLanguage />
            {switchComponent}
        </div>
    )
}

const EN = () => {
    return (
        <Center>EN</Center>
    )
}

const JP = () => {
    return (
        <Center>JP</Center>
    )
}

const Center = ({ children }) => {
    return (
        <div className='grid place-items-center text-black'>
            {children}
        </div>
    )
}