import React, { useState, useCallback, useContext, useEffect } from "react";
import { IoMic } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

import { AnswerBox } from "./AnswerBox";
import { QuestionBox } from "./QuestionBox";
import { ResultBox } from "./ResultBox";
import { LoadingIcon, SoundWaveIcon, TypingIcon } from '../icons';
import { getFirstQuestion, getNextQuestion, getRating } from "../api";
import { TextToSpeech } from "../lib/TextToSpeech";
import { LANGUAGES } from "../constants";
import { useQuestionCount } from "../hook/useQuestionCount";
import { LanguageContext } from "../context/language.context";
import { useSpeechRecognition } from "../hook/useSpeechRecognition";
import { OppsError } from "./OppsError";

export const InterviewAI = ({ email }) => {

    const { language: selectedLang } = useContext(LanguageContext);

    const [loadingPrompt, setLoadingPrompt] = useState(false);

    const { t } = useTranslation();

    const [isStarted, setStarted] = useState(false);

    const [chatHistory, setChatHistory] = useState([]);

    const [finishedInterview, setFinishedInterview] = useState(false);

    const [countQuestions, setCountQuestions] = useState(0);

    const {
        data: questionLimit,
        loading: questionCountLoading
    } = useQuestionCount();

    const {
        isRecording,
        startRecording,
        stopRecording,
        transcription,
        loading,
        error
    } = useSpeechRecognition({
        language: selectedLang
    });

    useEffect(() => {
        if (transcription) {
            const results = [{
                transcript: transcription,
                timestamp: Date.now()
            }]
            handleSubmitAnswer(results);
        }
    }, [transcription]);

    const handleStartInterview = useCallback(async () => {
        setStarted(true);
        const language = selectedLang === LANGUAGES.en.code ? 'en' : 'jp';
        const question = await getFirstQuestion({ email, language }, setLoadingPrompt)
            .then(it => it.message);
        if (question) {
            TextToSpeech.speak({
                text: question,
                language: selectedLang
            }, setLoadingPrompt);
            setChatHistory(prev => [...prev, { type: "question", value: question, timestamp: Date.now() }]);
            setCountQuestions(prev => prev + 1);
        }
    }, [selectedLang]);

    const handleSubmitAnswer = async (results) => {
        setChatHistory(prev => [...prev, { type: "answer", value: results, timestamp: Date.now() }]);
        const answer = results.map(it => it.transcript).join(" ");

        const language = selectedLang === LANGUAGES.en.code ? 'en' : 'jp';
        if (countQuestions < questionLimit) {
            const question = await getNextQuestion({ email, answer, language }, setLoadingPrompt)
                .then(it => it.message);

            if (question) {
                TextToSpeech.speak({
                    text: question,
                    language: selectedLang
                }, setLoadingPrompt);
                setChatHistory(prev => [...prev, { type: "question", value: question, timestamp: Date.now() }]);
                setCountQuestions(prev => prev + 1);
            }
        } else {
            const result = await getRating({ email, answer, language }, setLoadingPrompt)
                .then(it => it.message);
            if (result) {
                TextToSpeech.speak({
                    text: result,
                    language: selectedLang
                }, setLoadingPrompt);
                setChatHistory(prev => [...prev, { type: "result", value: result, timestamp: Date.now() }]);
                setFinishedInterview(true);
            }
        }
    }

    const handleStartAgain = () => {
        setFinishedInterview(false);
        setCountQuestions(0);
        setChatHistory([]);
        handleStartInterview();
    }

    const isXL = window.innerWidth >= 1280;

    return (
        <div className="w-full h-full debug p-2 overflow-y-auto border border-sky-500">
            <div className="flex items-center gap-2">
                <div className="w-full flex items-center gap-2 min-h-12">
                    {
                        finishedInterview ? (
                            <button
                                onClick={handleStartAgain}
                                className="bg-lime-500 px-2 py-1 text-stone-50">
                                {t('Start Again')}</button>
                        ) :
                            isStarted ? (
                                <>
                                    <button
                                        className="grid place-items-center rounded-full border-solid border-2 border-sky-500 p-2"
                                        onClick={isRecording ? stopRecording : startRecording}
                                    >
                                        <IoMic size={isXL ? 30 : 20} />
                                    </button>

                                    <div className="flex gap-1">
                                        {isRecording ? (
                                            <>
                                                <SoundWaveIcon />
                                                <span>(tap again to <span className="text-red-500">STOP</span>)</span>
                                            </>
                                        ) : t('Tap to Speak')}
                                    </div>
                                </>
                            ) : (
                                <button
                                    disabled={questionCountLoading}
                                    onClick={handleStartInterview}
                                    className="bg-lime-500 px-2 py-1 text-stone-50">
                                    {questionCountLoading ? 'Loading...' : t('Start Interview')}
                                </button>
                            )}
                </div>
            </div>
            <div>
                {chatHistory.map(it => {
                    return it.type === "question" ?
                        <QuestionBox value={it.value} key={it.timestamp} /> :
                        it.type === "answer" ?
                            <AnswerBox value={it.value} key={it.timestamp} /> :
                            <ResultBox value={it.value} key={it.timestamp} />
                })}
                {loadingPrompt ? <TypingIcon /> : null}
            </div>
            {
                error ? (
                    <OppsError message={error} />
                ) : null
            }
            {loading ? (
                <div className="flex justify-end">
                    <LoadingIcon />
                </div>
            ) : null}
        </div>
    );
}